{
  "addMoreRooms": "Add more rooms",
  "addMoreRoomsOrBook": "Explore Additional Rooms or Proceed with Booking",
  "addToCart": "Добави",
  "address": "Адрес",
  "adults": "Възрастни",
  "agreeWithTerms": "Съгласен съм с условията за ползване",
  "alreadyHaveProfile": "Вече имате профил",
  "apartment": "Апартамент",
  "applyFilter": "Филтрирай",
  "availableRooms": "Налични стаи",
  "average": "Средно",
  "bookingFilterInfo": "Моля, изберете дати",
  "buy": "Купи",
  "callUs": "Обадете ни се",
  "changeRooms": "Смени стаите",
  "checkIn": "Настаняване",
  "checkOut": "Напускане",
  "checkout": "Поръчка",
  "city": "Град",
  "close": "Затвори",
  "companyEmail": "Фирмен имейл",
  "companyName": "Име на фирма",
  "confirm": "Потвърди",
  "contactMailSent": "Вашето съобщение, беше изпратено успешно. Ще се свържем с Вас при първа възможност!",
  "count": "Брой",
  "country": "Държава",
  "dates": "Дати",
  "delivery": "Доставка",
  "deliveryData": "Данни за доставка",
  "description": "Описание",
  "eik": "ЕИК",
  "email": "Имейл",
  "enableNotifications": "Включи известия",
  "enableNotificationsInfo": "Включете известията за да получавате най-новите ни оферти и промоции",
  "enableNotificationsInfoOff": "Съжаляваме, но вашите известия са изключени. За да не изпуснете някоя от нашите страхотни оферти Ви молим да позволите получаването на известия",
  "entrance": "Вход",
  "fillYourReservationInfo": "Reservation Information",
  "fillYourReservationInfoSub": "Please Provide Your Reservation Details",
  "filter": "Филтър",
  "finishOrder": "Завърши поръчка",
  "firstName": "Първо име",
  "floor": "Етаж",
  "for": "За",
  "forPeriod": "Избрани дати",
  "forgotYourPassword": "Забравихте си паролата?",
  "from": "От",
  "from_date": "От дата",
  "gdprText": "С изпращането на това запитване към нас Вие предоставяте Ваши лични данни, които ние ще обработваме и съхраняваме съгласно Общия регламент за защита на данните на Европейския съюз (GDPR) и нашата Политика за конфиденциалност на данните , с която вие се съгласявате",
  "guests": "Гости",
  "inStock": "В наличност",
  "invoiceData": "Данни за фактура",
  "keepYourReservationNumbers": "Моля, запазете номерата на вашата резервация!",
  "kid": "Дете",
  "kidYears": "Години на дете",
  "kids": "Деца",
  "lastName": "Фамилия",
  "locale": "bg-BG",
  "login": "Вход",
  "logout": "Изход",
  "message": "Съобщение",
  "mol": "МОЛ",
  "moreFrom": "More от",
  "night": "нощ",
  "nights": "нощи",
  "noMoreRoom": "Няма налични стаи",
  "noRoomSelected": "No rooms selected",
  "note": "Забележка",
  "numberOfGuests": "Брой възрастни",
  "numberOfKids": "Брой деца",
  "order": "Поръчка",
  "orderFor": "Поръчка за",
  "orderNow": "Поръчай",
  "outOfStock": "Няма наличност",
  "password": "Парола",
  "paymentMethod": "Метод на плащане",
  "perNight": "за нощ",
  "phone": "Телефон",
  "pleaseSelectRoomsFirst": "Please select at least one room to proceed with the reservation.",
  "postCode": "Пощ. код",
  "price": "Цена",
  "product": "Продукт",
  "products": "Продукти:",
  "quarter": "Квартал",
  "readyProfile": "Вече имате профил?",
  "recover": "Възстанови",
  "reference": "Референция",
  "register": "Регистрация",
  "repeatPassword": "Повтори парола",
  "request": "Заявка",
  "requestPasswordChange": "Заяви парола",
  "requireInvoice": "Желая фактура",
  "reservation": "Резервация",
  "reservationDone": "Резервацията беше успешна!",
  "reservationSuccess": "Резервацията е успешна!",
  "reserve": "Резервирай",
  "returnPolicy": "Политика за връщане",
  "room": "Стая",
  "roomSelected": "Стаята е избрана!",
  "rooms": "Стаи",
  "saveAReservationHour": "Запази час",
  "search": "Търсене",
  "searchResult": "Резултати",
  "select": "Избери",
  "selectAdultsAndKids": "Choose the number of adults and kids",
  "selectCity": "Град",
  "selectCountry": "Държава",
  "selectGuests": "Изберете гости",
  "selectOffice": "Изберете офис",
  "selectRoom": "Изберете",
  "selectRooms": "Изберете стаи",
  "selectVariation": "Offers for this room",
  "selectedPeriodIsInvalid": "Невалидни дати",
  "sendMessage": "Изпрати запитване",
  "serviceRequested": "Услугата е заявена",
  "street": "Улица",
  "streetNumber": "Номер",
  "subject": "Тема",
  "sure": "Потвърди",
  "temporaryNotAvailable": "Временно недостъпно",
  "toAddress": "До адрес",
  "toOffice": "До офис",
  "to_date": "До дата",
  "total": "Общо",
  "totalSum": "Обща сума",
  "variations": "Вариянти",
  "vat": "ДДС номер",
  "view": "Преглед",
  "viewMore": "Виж още",
  "weOfferYou": "Предлагаме",
  "yearsOld": "Годишно",
  "yourCart": "Вашата количка",
  "yourCartIsEmpty": "Вашата количка е празна",
  "yourData": "Вашите данни",
  "yourEmail": "Имейл",
  "yourName": "Име",
  "yourOrder": "Поръчка",
  "yourPhone": "Телефон",
  "yourProducts": "Продукти",
  "adultsCount": "adultsCount",
  "kidsCount": "kidsCount"
}