import {axiosInstance, getAuthToken, removeAuthToken, saveAuthToken} from "@/services/Api/index";
import {defineStore, Store} from 'pinia'
import {AxiosResponse} from "axios";

export interface apiUserInterface {
    id: number;
    photo: string;
    photo_big:string;
    email:string
}
export interface UserClientInterface  extends Store{
    auth:apiUserInterface | null;
    login: (data: object)=>Promise<void>;
    register(data: object): Promise<void>;
    requestNewPassword(data: object): Promise<AxiosResponse>;
    resetPassword(data: object): Promise<void>;
    getByAuthToken(): void
    logout(): void
}
export const UserClient = defineStore({
    id: 'UserClient',
    state: () => ({
        _auth:null,
    }),
    getters: {
        auth:(state)=>state._auth,
    },
    actions: {
        async login(data:object):Promise<void> {
            return await axiosInstance
                .post('/auth/login', data)
                .then((response) => {
                    this._auth = response.data.data;
                    saveAuthToken(response.data.auth_token as string)
                })
        },
        async register(data: object): Promise<void> {
            return await axiosInstance
                .post('/auth/register', data)
                .then((response) => {
                    this._auth = response.data.data;
                    saveAuthToken(response.data.auth_token as string)
                })
        },
        async requestNewPassword(data:object):Promise<AxiosResponse> {
            return await axiosInstance
                .post('/auth/forgot', data)
        },
        async resetPassword(data:object):Promise<void> {
            return await axiosInstance.put('/auth/reset', data)
        },
        async getByAuthToken():Promise<void> {
            this._auth= getAuthToken()
            ? await axiosInstance
                .get('/auth/user' ,)
                .then((response) => response.data.data)
                .catch(() => this.logout())
                : null;
        },
        logout():void {
            this._auth= null;
            removeAuthToken();
        },

    }
})
