import {axiosInstance, getUserLang, saveUserLang} from "@/services/Api/index";
import {defineStore} from 'pinia'
import pusherPlugin from "@/services/pusherPlugin";
import {useHead} from "@vueuse/head";

export interface apiSettingsInterface {
    site_title: string;
    site_description:string;
    site_icon:string;
    logo: string;
    currency:string;
    default_language:string;
    sales_virtual:number;
    languages:{
        iso:string;
        name:string;
        flag:string;
    }[];
    users:{
        enable:number;
    },
    contacts:{
        primary:string;
        email:string;
        phone:string;
        coordinates:string;
        address:string;
    },
    store:{
        enable:number;
        fast_order:{
            enable:number;
            payment:string;
            header:string;
            description:string;
        },
        phone_order:{
            enable:number;
            header:string;
            description:string;
        }
    },
    pusherKey:string
    pusherChannel:string;
    header_theme:string;
    css:string;
}
export interface SettingsClientInterface{
    all:apiSettingsInterface;
    load():Promise<void>
}
export const SettingsClient = defineStore({
    id: 'SettingsClient',
    state: () => ({
        _list:{} as apiSettingsInterface,
    }),
    getters: {
        all: (state)=>state._list
    },
    actions: {
        async load():Promise<void> {
            this._list = await axiosInstance
                .get('/settings' )
                .then((response) => {
                    let settings=response.data;
                    if (!getUserLang()){
                        saveUserLang(settings.default_language)
                    }
                    if(settings.pusherKey) {
                        pusherPlugin(settings.pusherKey,settings.pusherChannel);
                    }
                    if(settings.css) {
                        useHead({
                            link: [
                                {
                                    key: 'api-css',
                                    rel: `stylesheet`,
                                    href: settings.css,
                                },
                            ]
                        });
                    }
                    return settings
                })
        },

    }
})
