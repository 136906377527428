const userLoginView = () => import("@/views/authPages/userLoginView.vue")
const UserRegisterView = () => import("@/views/authPages/userRegisterView.vue")
const userRecoverView = () => import("@/views/authPages/userRecoverView.vue")
const userResetPassword = () => import("@/views/authPages/userResetPassword.vue")
const ProfileView = () => import("@/views/authPages/profileView.vue")

export const usersPages = [
    {
        path: '/auth/login',
        name: 'auth.login',
        component: userLoginView
    },
    {
        path: '/auth/register',
        name: 'auth.register',
        component: UserRegisterView
    },
    {
        path: '/auth/recover',
        name: 'auth.recover',
        component: userRecoverView
    },
    {
        path: '/auth/resetPassword/:id/:token',
        name: 'auth.resetPassword',
        component: userResetPassword,
    },
    {
        path: '/profile',
        name: 'profile',
        component: ProfileView
    },
];