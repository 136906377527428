import {axiosInstance} from "@/services/Api/index";
import {defineStore, Store} from 'pinia'
import {apiProductsCategoriesInterface} from "@/services/Api/ProductsClient";
import {apiBasicInterface} from "@/services/Api/apiBasicResponse";

export interface apiNewsInterface extends apiBasicInterface{
    categories:number[];
    photos:[];
}

export interface NewsClientInterface extends Store{
    loadCategories():void
    load():Promise<void>
    all:apiNewsInterface[];
    allCategories: apiProductsCategoriesInterface[];
    findCategoryByUrl(url:string): apiProductsCategoriesInterface | undefined;
    findCategoryById(id:number):apiProductsCategoriesInterface | undefined;
    similarNews(product: apiNewsInterface):apiNewsInterface[];
    allFromCategory(category:apiProductsCategoriesInterface):apiNewsInterface[];
    findByUrl(url:string):apiNewsInterface | undefined;
}

export const NewsClient = defineStore({
    id: 'NewsClient',
    state: () => ({
        _categories:[] as apiProductsCategoriesInterface[],
        _list:[] as apiNewsInterface[],
    }),
    getters: {
        //categories
        allCategories: (state)=>state._categories,
        findCategoryByUrl: (state) =>(url: string) => state._categories.find((info: apiProductsCategoriesInterface) => info.url === url),
        findCategoryById: (state) =>(id: number) => state._categories.find((info: apiProductsCategoriesInterface) => info.id === id),

        //news
        all: (state)=>state._list,
        similarNews: (state)=>(news: apiNewsInterface)=>state._list.filter((info: apiNewsInterface) => info.categories.includes(news.categories[0])),
        allFromCategory(state) {
            return (category: apiProductsCategoriesInterface) => {
                if(!category){
                    return [];
                }
                const children: number[] = [...category.child];
                children.push(category.id);
                return state._list.filter(info => info.categories.some(v => children.indexOf(v) !== -1))
            }
        },
        findByUrl: (state)=>(url: string)=> state._list.find((info: apiNewsInterface) => info.url === url),
    },
    actions: {
        async loadCategories():Promise<void> {
            this._categories = await axiosInstance
                .get('/categories', {params: {type: 2}})
                .then((response) => response.data.data)
        },
        async load():Promise<void> {
            this._list = await axiosInstance
                .get('/news')
                .then((response) => response.data.data)
        },


    }
})
