import {axiosInstance} from "@/services/Api/index";
import {defineStore} from 'pinia'
import {apiBasicInterface} from "@/services/Api/apiBasicResponse";
import {AxiosResponse} from "axios";

export interface apiProductInterface extends apiBasicInterface{
    categories:number[];
    attributes:{[key: number]: number[]}
    options:apiProductOptions[];
    product_weight:number;
    product_define:string;
    price:number;
    photos:[];
    enable_reservations:number;
    working_time:string[];
    working_days:number[];
    duration:number;
    use_availability:number;
    count:number;
    discount:number;
    code:string
}
export interface apiProductOptions {
    [key: number]: {
        on: number;
        count: number;
        price: number;
        percent: number;
        photo: string;
    }
}
export interface apiProductsCategoriesInterface extends apiBasicInterface{
    type:number;
    parent_id:number;
    template:string;
    child:number[];
}
export interface reservedDays {reservation_from:string,reservation_to:string}
export interface ProductsClientInterface{
    loadCategories():void
    load():Promise<void>
    getReservedDaysApi(id:number):Promise<void>;

    allCategories: apiProductsCategoriesInterface[];
    allChildFromCategory(category:apiProductsCategoriesInterface): apiProductsCategoriesInterface[];
    findCategoryByUrl(url:string): apiProductsCategoriesInterface | undefined;
    findCategoryById(id:number):apiProductsCategoriesInterface | undefined;
    all:apiProductInterface[];
    similarProducts(product: apiProductInterface):apiProductInterface[];
    allFromCategory(category:apiProductsCategoriesInterface):apiProductInterface[];
    findByUrl(url:string):apiProductInterface | undefined;
    findById(id:number):apiProductInterface | undefined;
    getReservedDays:reservedDays[];
    tmpFetch:apiProductInterface;
}


export const ProductsClient= defineStore({
    id: 'ProductsClient',
    state: () => ({
        _categories:[] as apiProductsCategoriesInterface[],
        _list:[] as apiProductInterface[],
        _reserved:[],
        _tmp_product:{} as apiProductInterface
    }),
    getters: {
        //categories
        allCategories: (state) => state._categories,
        allChildFromCategory: (state) => (category: apiProductsCategoriesInterface) => state._categories.filter((info: apiProductsCategoriesInterface) => info.parent_id === category.id),
        findCategoryByUrl: (state) => (url: string) => state._categories.find((info: apiProductsCategoriesInterface) => info.url === url),
        findCategoryById: (state) => (id: number) => state._categories.find((info: apiProductsCategoriesInterface) => info.id === id),

        //products
        all: (state) => state._list,
        tmpFetch: (state) => state._tmp_product,
        similarProducts: (state) => (product: apiProductInterface) => state._list.filter((info: apiProductInterface) => info.categories.includes(product.categories[0])),
        allFromCategory(state) {
            return (category: apiProductsCategoriesInterface) => {
                if(!category){
                    return [];
                }
                const children: number[] = [...category.child];
                children.push(category.id);
                return state._list.filter(info => info.categories.some(v => children.indexOf(v) !== -1))
            }
        },
        findByUrl: (state) => (url: string) => state._list.find((info: apiProductInterface) => info.url === url),
        findById: (state) => (id: number) => state._list.find((info: apiProductInterface) => info.id === id),
        getReservedDays:(state) =>state._reserved,
    },
    actions: {
        async loadCategories():Promise<void> {
            this._categories = await axiosInstance
                .get('/categories', {params: {type: 1}})
                .then((response) => response.data.data)
        },
        async load():Promise<void> {
            try {
                const response = await axiosInstance.get('/products');
                this._list = response.data.data;
            } catch (error) {
                console.error('Error fetching products:', error);
            }
            },
        async getReservedDaysApi(id:number):Promise<void> {
            this._reserved=  await axiosInstance
                .get('/products/reserved/'+id)
                .then((response)=>response.data.data);

        },
    }
})
