const bookingVew = () => import("@/views/products/bookingView.vue")
const roomsPageListView = () => import("@/views/products/roomsPageListView.vue")
const roomsPageShowView = () => import("@/views/products/roomsPageShowView.vue")

export const bookingsPages = [
    {
        path: '/booking',
        name: 'booking.view',
        component: bookingVew,
    },
    {
        path: '/rooms',
        name: 'rooms.list',
        component: roomsPageListView
    },
    {
        path: '/rooms/:url',
        name: 'rooms.view',
        component: roomsPageShowView
    },
];
