import {AxiosResponse} from "axios";
import {axiosInstance} from "@/services/Api/index";
import {defineStore, Store} from "pinia";

export interface apiOptionItem {
    id: number;
    name: string;
    type: number;
    options: object;
    show_order: number;
    values: apiOptionValueItem[];
}
export interface apiOptionValueItem {
    id: number;
    name: string;
    option_id: number;
    photo: string;
    show_order: number;
}
export interface OptionsClientInterface extends Store{
    load:()=>void;
    all: apiOptionItem[];
    allOptions: apiOptionItem[];
    allAttributes: apiOptionItem[];
    findById(id: number):apiOptionItem | undefined;
    findOptionValue(option_id:number,value_id:number): apiOptionValueItem | undefined;
    findOptionValueFullSearch(value_id:number): apiOptionItem | undefined;
}
export const OptionsClient = defineStore({
    id: 'OptionsClient',
    state: () => ({
        _list:[] as apiOptionItem[],
        _values:[] as apiOptionItem[],
    }),
    getters: {
        all: (state)=>state._list,
        allOptions: (state)=>state._list.filter((option)=>option.options===null),
        allAttributes: (state)=>state._list.filter((option)=>option.options !== null),
        findById: (state) => (id: number) => state._list.find((info:apiOptionItem) => info.id == id),
        findOptionValue(){
            return  (option_id:number,value_id:number)=>this.findById(option_id)?.values.find((info:apiOptionValueItem) => info.id == value_id)
        },
        findOptionValueFullSearch:(state)=>(id: number):apiOptionItem | undefined =>  state._values.find((info:apiOptionItem) => info.id == id),
    },
    actions: {
        async load():Promise<void> {
            this._list = await axiosInstance
                .get('/options' )
                .then((response:AxiosResponse<any,[]>) => {
                    let r=response.data.data;
                    for(let i:number=0;i<r.length;i++){
                        this._values = [...this._values, ...r[i].values];
                    }
                    return r;
                })
        },
    }
})

