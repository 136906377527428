<template>
    <div class="loader" >
        <div class="dots" >
            <div
                    v-for="index in 100"
                    :key="index"
                    :style="{ '--i': index }"
                    class="dot"
            ></div>
        </div>
        <div class="text">
            <span class="letter" v-for="(letter, index) in text" :key="index" :style="{ '--i': index }">{{ letter }}</span>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            text: 'NAVTECH'.split(''),
        };
    },
};
</script>

<style scoped lang="scss">
.loader {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    font-size: 2em;
    font-weight: bold;
    color: #888;
    width: 50%;
    margin: 200px 25%;
}

.letter {
  font-family:'initial' !important;
    position: relative;
    animation: bounce 4s cubic-bezier(0.5, 0.05, 0.2, 1) infinite both,
    colorChange 1s linear infinite both;
    animation-delay: calc(0.1s * var(--i));
}

.text {
    position: relative;
    z-index: 2;
}

.dots {
    position: absolute;
    width: calc(100% + 2em);
    height: calc(100% + 2em);
    display: flex;
    flex-wrap: wrap;
}

.dot {
    flex-grow: 1;
    width: 5px;
    height: 5px;
    background-color: var(--bs-dark);
    border-radius: 50%;
    opacity: 0;
    animation: fade 4s linear infinite both;
    animation-delay: calc(0.04s * var(--i));
}

@keyframes bounce {
    0%,
    100% {
        transform: translateX(0);
    }
    50% {
        transform: rotate(360deg) translateX(20px);
    }
}

@keyframes colorChange {
    0%   {color: #FFA800;}
    25%  {color: #C44900;}
    50%  {color: #0E9594;}
    75%  {color: #2bcbba;}
    100% {color: var(--bs-dark);}
}

@keyframes fade {
    0%, 100% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
}
</style>