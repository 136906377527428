import {axiosInstance} from "@/services/Api/index";
import {defineStore, Store} from 'pinia'
import {AxiosResponse} from "axios";


export interface apiPaymentsInterface {
    service: string;
    name:string;
    redirect:string;
}
export interface PaymentsClientInterface  extends Store{
    loadServices():Promise<void>
    services:apiPaymentsInterface[];
    redirectTo(service:string):string | undefined

}
export const PaymentsClient = defineStore({
    id: 'PaymentsClient',
    state: () => ({
        _services:[] as apiPaymentsInterface[],
    }),
    getters: {
        services: (state)=>state._services,
        redirectTo: (state)=>(service:string)=>state._services.find(info => info.service == service)?.redirect,
    },
    actions: {
        async loadServices():Promise<void> {
            this._services = await axiosInstance
                .post('/payments/services')
                .then((response:AxiosResponse<any,[]>) => response.data.data)
        },
    }
})
