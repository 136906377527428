import {AxiosResponse} from "axios";
import { axiosInstance} from "@/services/Api/index";
import {defineStore, Store} from "pinia";
import {apiBasicInterface} from "@/services/Api/apiBasicResponse";
import {apiProductsCategoriesInterface} from "@/services/Api/ProductsClient";

export interface apiRoomsInterface extends apiBasicInterface {
        max_adults: number;
        max_kids: number;
        count:number;
        variations:apiRoomsVariationsInterface[]
}
export interface apiRoomsVariationsInterface extends apiBasicInterface,RoomFilterRequestInterface {
    price: number;
    min:number;
}
export interface RoomFilterRequestInterface {
    to_date: string ;
    from_date: string ;
}
export interface BookingResultInterface {
    reservations:string[];
}

export interface BookingRoomInterface extends RoomFilterRequestInterface{
    variant_id: number;
    room_id: number;
    adults: number;
    kids: number
    photo:string;
    name:string;
    price:number;
    max_adults:number;
    max_kids:number;
}

export interface BookingSaveInterface extends RoomFilterRequestInterface{
    rooms: BookingRoomInterface[];
    name:string;
    email:string,
    phone:string,
    note:string,
}

export interface RoomsClientInterface  extends Store{
    load():Promise<void>;
    loadVariations():Promise<void>;
    hotel:apiProductsCategoriesInterface;
    search(formSearch:Partial<RoomFilterRequestInterface>):Promise<void>;
    clear():void;
    all: apiRoomsInterface[];
    allVariations: apiRoomsVariationsInterface[];
    findById(id: number):apiRoomsInterface | undefined;
    findByUrl(url: string):apiRoomsInterface | undefined;
    result:apiRoomsInterface[];
    getSelectedRooms:BookingRoomInterface[];
    getFilter:RoomFilterRequestInterface;
    addRoom(room:BookingRoomInterface):void;
    removeRoom(roomId:number):void;
    getRealPrice(room:BookingRoomInterface):Promise<AxiosResponse<BookingRoomInterface>>
    doBook(data:BookingSaveInterface):Promise<AxiosResponse<BookingResultInterface>>

}

export const RoomsClient = defineStore({
    id: 'RoomsClient',
    state: () => ({
        _list:[] as apiRoomsInterface[],
        _list_variations: [] as apiRoomsVariationsInterface[],
        _filter:{} as Partial<RoomFilterRequestInterface>,
        _search:[] as apiRoomsInterface[],
        _selected_rooms: [] as BookingRoomInterface[],
        _hotel:{} as apiProductsCategoriesInterface,
    }),
    getters: {
        all: (state):apiRoomsInterface[]=>state._list,
        allVariations: (state):apiRoomsVariationsInterface[]=>state._list_variations,
        hotel: (state):apiProductsCategoriesInterface=>state._hotel,
        findById: (state) => (id: number) => state._list.find((info: apiRoomsInterface) => info.id === id),
        findByUrl: (state) => (url: string) => state._list.find((info: apiRoomsInterface) => info.url === url),
        result: (state):apiRoomsInterface[]=>state._search,
        getSelectedRooms:  (state) => state._selected_rooms,
        getFilter:  (state) => state._filter,
},
    actions: {
        async load():Promise<void> {
            this._list = await axiosInstance
                .get('/rooms' )
                .then((response:AxiosResponse<any,[]>) => response.data.data)
                .catch(() => {})
        },
        async loadVariations():Promise<void> {
             await axiosInstance
                .get('/rooms/daily-rates' )
                .then((response:AxiosResponse<any,[]>) => {
                    this._list_variations=response.data.list;
                    this._hotel=response.data.hotel;
                })
                .catch(() => {})
        },
        async search(formSearch:Partial<RoomFilterRequestInterface>):Promise<void> {
            this._filter=formSearch;
            this._search = await axiosInstance
                .post('/rooms/search',formSearch )
                .then((response:AxiosResponse<any,[]>) => response.data.data)
                .catch(() => {})
        },
        async getRealPrice(room:BookingRoomInterface):Promise<AxiosResponse<BookingRoomInterface>> {
            return await axiosInstance
                .post('/rooms/get-real-price',room )
        },
        clear(){
            this._search=[];
            this._selected_rooms=[];
            this._filter={} as RoomFilterRequestInterface;
        },
        addRoom(room:BookingRoomInterface){
            try {
                this._selected_rooms.push(room);
            } catch (e) {
                this._selected_rooms=[];
                this._selected_rooms.push(room);
            }
        },
        removeRoom(index:number){
            this._selected_rooms.splice(index, 1);
        },
        async doBook(data:BookingSaveInterface):Promise<AxiosResponse> {
            return await axiosInstance.post('/rooms/book', data)
        },
    }
})

