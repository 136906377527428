import {AxiosResponse} from "axios";
import {axiosInstance} from "@/services/Api/index";
import {defineStore, Store} from "pinia";

export interface apiMenuInterface {
    position: number;
    hidden: string;
    items: {
        name:string;
        page_url:string;
    }[]
}
export interface MenuClientInterface  extends Store{
    load():Promise<void>
    all: apiMenuInterface[];
    findByPosition: (position: number) => apiMenuInterface | undefined;
}

export const MenuClient = defineStore({
    id: 'MenuClient',
    state() {
        return ({
            _list: [] as apiMenuInterface[],
        });
    },
    getters: {
        all: (state)=>state._list,
        findByPosition:  (state) =>(position: number) => state._list.find(info => info.position === position),

    },
    actions: {
        async load():Promise<void> {
            this._list = await axiosInstance
                .get('/menu' )
                .then((response) => response.data.data)
        },
    }
})

