import router from "@/router";

export const improveLinks = {
    mounted(el:Document ):void {
        const links:NodeListOf<HTMLAnchorElement > = getLinks(el);
        setLinksOpenInHistoryMode(links);
    },
}
const setLinksOpenInHistoryMode=(els:NodeListOf<HTMLAnchorElement >):void => {
    els.forEach((link:HTMLAnchorElement ) => {
        if (link.hostname === window.location.hostname) {
            let href:string=link.getAttribute('href') as string;
            link.setAttribute('href','javascript:void(0)');
            link.addEventListener('click', async function ():Promise<void> {
                await router.push(href);
            });
            link.setAttribute('data-ready', '');
        }
    });
}
const getLinks=(el:Document):NodeListOf<HTMLAnchorElement > =>{
    const selector:string = 'a:not([data-ready]):not([target="_blank"])';
    return el.querySelectorAll(selector)
}