import {axiosInstance} from "@/services/Api/index";
import {defineStore} from "pinia";
import {apiBasicInterface} from "@/services/Api/apiBasicResponse";
import {AxiosResponse} from "axios";

export interface apiInfoInterface extends apiBasicInterface{

}
export interface contactFormInterface {
    name:string;
    email:string;
    phone:string;
    subject:string;
    message:string;
    terms:boolean;
}
export interface InfoClientInterface{
    load:()=>Promise<void>;
    all: apiInfoInterface[];
    findByUrl: (url: string) => apiInfoInterface | undefined;
    sendMessage(data: contactFormInterface): Promise<AxiosResponse>
}

export const InfoClient = defineStore({
    id: 'InfoClient',
    state: () => ({
        _list:[] as apiInfoInterface[],
    }),
    getters: {
        all: (state)=>state._list,
        findByUrl:  (state) => (url: string) => state._list.find(info => info.url == url),

    },
    actions: {
        async load():Promise<void> {
            this._list = await axiosInstance
                .get('/info' )
                .then((response) => response.data.data)
        },
        async sendMessage(data: contactFormInterface): Promise<AxiosResponse> {
            return await axiosInstance
                .post('/info/send-message', data)
        },
    }
})

