import {createRouter, createWebHistory} from "vue-router";
import {ordersPages} from "@/router/ordersPages";
import {productsPages} from "@/router/productsPages";
import {usersPages} from "@/router/usersPages";
import {Ref, ref} from "vue";
import {bookingsPages} from "@/router/bookingPages";
// @ts-ignore
import NProgress from 'nprogress';
import {newsPages} from "@/router/newsPages";

const homeView = () => import("@/views/homeView.vue")
const infoPagesShowView = () => import("@/views/infoPages/infoPagesShowView.vue")
export const errorMessages:Ref<string|boolean|object> = ref(false);
export const resultMessages:Ref<string|boolean|object> = ref(false);
export const loadingMessages:Ref<boolean> = ref(false);

export const sectionArea = ref({
  page: null as string | null,
  page_url: null as string | null,
});

const routes = [
  {
    path: "/",
    name: "home-page",
    component: homeView,
  },
  {
    path: "/info/:url",
    name: "info.view",
    component: infoPagesShowView,
  },
  ...ordersPages,
  ...productsPages,
  ...usersPages,
  ...bookingsPages,
    ...newsPages
];

const router = createRouter({
  history: createWebHistory('/'),
  scrollBehavior(to,from) {
    if(to.path==='/products/search-products') {
      return;
    }
    return { top: 0 };
  },
  routes,
});
router.beforeResolve((to, from, next) => {
  if (to.name) {
    NProgress.start()
  }
  sectionArea.value.page = null;
  sectionArea.value.page_url = null;
  next()
})
router.afterEach((to, from) => {
  NProgress.done()
})

export default router;
