import {axiosInstance} from "@/services/Api/index";
import {defineStore, Store} from 'pinia'
import {AxiosResponse} from "axios";

export interface apiCountryInterface {
    iso: string;
    name:string;
}
export interface apiCitiesInterface {
    postCode: string;
    name:string;
}
export interface apiOfficesInterface {
    office_id: string;
    name:string;
}
export interface apiDeliveryServices {
    service: string;
    name:string;
}
export interface apiDeliveryLocations {
    address: number;
    office:number;
}
export interface apiDeliveryCalculation {
    deliveryPrice:number,
    deliveryDate:number,
    deliveryText:null
}
export interface DeliveryClientInterface  extends Store{
    loadServices():Promise<void>
    loadCountries():Promise<void>
    loadCities(iso:string):Promise<void>
    loadOffices(service:string,iso:string,city:string,postCode:string):Promise<void>
    calculate(data: object):Promise<AxiosResponse<apiDeliveryCalculation>>
    locations:apiDeliveryLocations;
    services:apiDeliveryServices[];
    countries:apiCountryInterface[];
    cities:apiCitiesInterface[];
    offices:apiOfficesInterface[];

}
export const DeliveryClient = defineStore({
    id: 'DeliveryClient',
    state: () => ({
        _countries:[] as apiCountryInterface[],
        _cities:[] as apiCitiesInterface[],
        _offices:[] as apiOfficesInterface[],
        _services:[] as apiDeliveryServices[],
        _locations:{} as apiDeliveryLocations,
    }),
    getters: {
        countries: (state)=>state._countries,
        cities: (state)=>state._cities,
        offices: (state)=>state._offices,
        services: (state)=>state._services,
        locations: (state)=>state._locations,
    },
    actions: {
        async loadServices():Promise<void> {
            await axiosInstance
                .post('/deliveries/services')
                .then((response:AxiosResponse<any,[]>) => {
                    this._services = response.data.data;
                    this._locations = response.data.to;
                })
        },
        async loadCountries():Promise<void> {
            this._countries = await axiosInstance
                .post('/deliveries/countries')
                .then((response:AxiosResponse<any,[]>) => response.data.data)
        },
        async loadCities(iso:string):Promise<void> {
            this._cities = await axiosInstance
                .post('/deliveries/cities',{'iso':iso} )
                .then((response:AxiosResponse<any,[]>) => response.data.data)
        },
        async loadOffices(service:string,iso:string,city:string,postCode:string):Promise<void> {
            this._offices = await axiosInstance
                .post('/deliveries/offices',{service:service,'iso':iso,city:city,postCode:postCode} )
                .then((response:AxiosResponse<any,[]>) => response.data.data)
        },
        async calculate(data:object):Promise<AxiosResponse<apiDeliveryCalculation>> {
            return await axiosInstance.post('/deliveries/calculate', data)
        },

    }
})
