<script setup lang="ts">
import {defineProps} from 'vue';

const props = defineProps({
    errors: {
        type: [String,Boolean,Array, Object],
        required: false,
        default:false,
    },
    result:  {
        type: [String,Boolean,Array, Object],
        required: false,
        default:false,
    },
})
const isString = (value) => typeof value === 'string' || value instanceof String
const isObject = (value) => typeof value === 'object' || value instanceof Object
</script>

<template>
  <template v-if="props.errors">
      <div class="alert alert-danger" v-if="isString(props.errors)">{{props.errors}}</div>
      <div v-if="isObject(props.errors)">
          <ul class="list-group list-group-flush" v-for="(item,key) of props.errors">
              <li class="list-group-item list-group-item-danger">{{key}} - {{item.toString()}}</li>
          </ul>
      </div>
  </template>
    <div v-if="props.result" class="alert alert-success">
        <div v-if="isString(props.result)">{{props.result}}</div>
    </div>
</template>