import {AxiosResponse} from "axios";
import {axiosInstance} from "@/services/Api/index";
import {defineStore} from "pinia";

export interface apiSectionsInterface {
    id: number;
    page:string;
    page_url: string;
    show_order: number;
    view: string;
    sub_view: string;
    limit:number|null;
    class: string;
    photo: string;
    photo1: string;
    photo2: string;
    photo3: string;
    photo4: string;
    name: string;
    description: string;
    external_url: string;
    class_items:string;
    category_info:string;
    description_products:string;
    template:string;
    product_id:number
}

export interface SectionsClientInterface{
    all: apiSectionsInterface[];
    allByUrl: (page: string,page_url: string) => apiSectionsInterface[];
    load:()=>Promise<void>;
}

export const SectionsClient = defineStore({
    id: 'SectionsClient',
    state: () => ({
        _list:[] as apiSectionsInterface[],
    }),
    getters: {
        all: (state):apiSectionsInterface[]=>state._list,
        allByUrl: (state) =>
            (page:string, page_url: string):apiSectionsInterface[] => {
            return state._list.filter(info => info.page == page).filter(info => info.page_url == page_url);
        },
},
    actions: {
        async load():Promise<void> {
            this._list = await axiosInstance
                .get('/sections' )
                .then((response:AxiosResponse<any,[]>) => response.data.data)
        },
    }
})

