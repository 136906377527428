import {ProductsClient, ProductsClientInterface} from "@/services/Api/ProductsClient";
import {InfoClient, InfoClientInterface} from "@/services/Api/InfoClient";
import {SettingsClient, SettingsClientInterface} from "@/services/Api/SettingsClient";
import {OptionsClient, OptionsClientInterface} from "@/services/Api/OptionsClient";
import {SectionsClient, SectionsClientInterface} from "@/services/Api/SectionsClient";
import {UserClient, UserClientInterface} from "@/services/Api/AuthClient";
import axios, {AxiosRequestConfig} from "axios";
import {MenuClient, MenuClientInterface} from "@/services/Api/MenuClient";
import i18n from "@/lang";
import {OrdersClient, OrdersClientInterface} from "@/services/Api/OrdersClient";
import {errorMessages, loadingMessages} from "@/router";
import {CartClient, CartClientInterface} from "@/services/Api/CartClient";
import {RoomsClient, RoomsClientInterface} from "@/services/Api/RoomsClient";
import {DeliveryClient, DeliveryClientInterface} from "@/services/Api/DeliveryClient";
import {PaymentsClient, PaymentsClientInterface} from "@/services/Api/PaymentsClient";
import {NewsClient, NewsClientInterface} from "@/services/Api/NewsClient";

export const saveAuthToken = (token: string): void => localStorage.setItem('_token', token);
export const getAuthToken = (): string | null => localStorage.getItem('_token');
export const removeAuthToken = (): void => localStorage.removeItem('_token');
export const saveUserLang = (language: string): void => {
    if (language.length !== 2) {
        return;
    }
    localStorage.setItem('_language', language);
    i18n.global.locale.value = language;
}
export const getUserLang = (): string | null => localStorage.getItem('_language');
i18n.global.locale.value = getUserLang();

export const axiosConfiguration: AxiosRequestConfig = {
    baseURL: import.meta.env.VITE_API_BASE_URL,
    timeout: 10000,
    maxContentLength: 100000000,
    maxBodyLength: 1000000000,
    headers: {
        'API-KEY': import.meta.env.VITE_API_API_KEY,
        'User-Language': getUserLang(),
        'Authorization': 'Bearer ' + getAuthToken()
    }
}
export const axiosInstance = axios.create(axiosConfiguration);
axiosInstance.interceptors.request.use((config) => {
    loadingMessages.value = true;
    return config;
}, (error) => {
    loadingMessages.value = false;
    return Promise.reject(error);
});

axiosInstance.interceptors.response.use((response) => {
    loadingMessages.value = false;
    return response;
}, (error) => {
    switch (error.response.status) {
        case 406:
            errorMessages.value = error.response.data.errors;
            break
        case 401:
            errorMessages.value = error.response.data.message;
            break;
    }
    loadingMessages.value = false;
    return Promise.reject(error);
});

export interface apiResponseInterface<T> {
    data: T;
}
class ApiClass {
    public products!: ProductsClientInterface;
    public settings!: SettingsClientInterface;
    public sections!: SectionsClientInterface;
    public infos!: InfoClientInterface;
    public options!: OptionsClientInterface;
    public user!: UserClientInterface
    public menu!: MenuClientInterface;
    public orders!: OrdersClientInterface;
    public cart!: CartClientInterface;
    public rooms!: RoomsClientInterface;
    public delivery!: DeliveryClientInterface
    public payments!: PaymentsClientInterface
    public news!: NewsClientInterface

    init() {

        this.settings = SettingsClient();
        this.cart = CartClient();
        this.menu = MenuClient();
        this.infos = InfoClient();
        this.sections = SectionsClient();
        this.user = UserClient();
        this.user.getByAuthToken();
        this.options = OptionsClient();
        this.products = ProductsClient();
        this.news = NewsClient();
        this.orders = OrdersClient();
        this.rooms = RoomsClient();
        this.delivery = DeliveryClient();
        this.payments = PaymentsClient();
    }
}

const Api: ApiClass = new ApiClass()
export default Api;

