import {axiosInstance} from "@/services/Api/index";
import {defineStore, Store} from 'pinia'
import {AxiosResponse} from "axios";


export interface OrdersClientInterface  extends Store{
    doOrderSingle(data: object):Promise<AxiosResponse>
    doOrder(data: object):Promise<AxiosResponse>

}
export const OrdersClient = defineStore({
    id: 'OrdersClient',
    state: () => ({
        _auth:null,
    }),
    getters: {

    },
    actions: {
        async doOrderSingle(data:object):Promise<AxiosResponse> {
            return await axiosInstance.post('/orders/store-single', data)
        },
        async doOrder(data:object):Promise<AxiosResponse> {
            return await axiosInstance.post('/orders', data)
        },

    }
})
