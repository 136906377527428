const SearchView = () => import("@/views/products/searchView.vue");
const ProductPageShowView = () =>
  import("@/views/products/productPageShowView.vue");
const categoryPageShowView = () =>
  import("@/views/products/categoryPageShowView.vue");
const categoriesPageListView = () =>
  import("@/views/products/categoriesPageListView.vue");

export const productsPages = [
  {
    path: "/products/search-products",
    name: "products.search",
    component: SearchView,
  },
  {
    path: "/product/:url",
    name: "products.view",
    component: ProductPageShowView,
  },
  {
    path: "/categories/:url",
    name: "category.list",
    component: categoriesPageListView,
  },
  {
    path: "/category/:url",
    name: "category.view",
    component: categoryPageShowView,
  },
];
