const CartShowView = () => import("@/views/orders/cartShowView.vue")
const checkoutView = () => import("@/views/orders/checkoutView.vue")
const cashThankYou = () => import("@/views/orders/payments/cashView.vue")

export const ordersPages = [
    {
        path: '/cart',
        name: 'cart',
        component: CartShowView
    },
    {
        path: '/checkout',
        name: 'checkout',
        component: checkoutView
    },
    {
        path: '/orders/cash/thank_you/:url',
        name: 'orders.cash.thank_you',
        component: cashThankYou
    },
];