import {defineStore, Store} from 'pinia'
import {apiProductInterface} from "@/services/Api/ProductsClient";
// @ts-ignore
import {createToaster} from "@meforma/vue-toaster";

const toaster = createToaster({ /* options */ });

 export interface cartItemInterface{
    id:number;
    selected_count:number;
    icon:string;
     price:number;
     name:string;
     url:string;
     selected_options:null|object;
}
    interface mixedInterface extends apiProductInterface,cartItemInterface{}

export interface CartClientInterface extends Store{
    load():void
    save():void
    update(product: mixedInterface, selected_count: number,price:number,selected_options:null|object):void
    remove(key:number):void;
    addToCart (item:apiProductInterface,selected_count:number,price:number,selected_options?:null|object):void;
    all:cartItemInterface[];
    total_amount:number;
    total_items:number;
}
export const CartClient = defineStore({
    id: 'CartClient',
    state: () => ({
        _list:[] as cartItemInterface[],
    }),
    getters: {
        all: (state) =>state._list,
        total_amount:(state)=>state._list.reduce((total, item) => total + (item.selected_count*item.price), 0),
        total_items:(state)=> state._list.reduce((total, item) =>  total + item.selected_count, 0),

    },
    actions: {
        load():void {
            this._list=JSON.parse(localStorage.getItem('cart') ?? '[]');
        },
        update(product: mixedInterface, selected_count: number,price:number,selected_options:null|object):void {
            if (selected_count===-1 && product.selected_count<=1 ) return;
            const cart_product:cartItemInterface = {
                'id':product.id,
                'selected_count': 1,
                'icon':product.icon,
                'price':price,
                'name':product.name,
                'url':product.url,
                'selected_options': {...selected_options},
            }
            let exists:number = this._list.findIndex(item =>
                item.id === cart_product.id && JSON.stringify(item.selected_options)===JSON.stringify(cart_product.selected_options));
            if (exists === -1) {
                this._list.push(cart_product);
            } else {
                this._list[exists].selected_count += selected_count;
            }
            this.save();
        },
        save():void{
            localStorage.setItem('cart',JSON.stringify(this._list));
        },
        remove(key:number):void{
            this._list.splice(key, 1);
            this.save();
        },
        addToCart (item:mixedInterface,selected_count:number,price:number,selected_options:null|object =null):void {
            this.update(item,selected_count,price,selected_options);
            toaster.show(
                '+ '+selected_count+' '+item.name,
                {
                    type:'warning',
                    position:"top-right",
                    duration:1000
                }
            )
        }
    },



})
